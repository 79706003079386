import React from 'react';

/* Components */
import img from '../../../images/PAS-DE-FLOU-1.jpg'

/* Component styling */
import styles from './Footer.module.scss';

const Footer = () => {

    return (
          <footer className={`${styles.footer_Component}`}>

              <img src={img} alt="Photo de Anthony Nuckley" />

          </footer>
    );
  }
  
export default Footer;