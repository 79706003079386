import {useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

/* Context */
import { MessageContext } from '../components/Message/Context/Message.Context'

/* Layouts */
import Footer from '../components/layouts/Footer/Footer';

/* Spinner component for the Suspence element */
import Spinner from '../utils/Spinner/Spinner'

//Message component
import Message from '../components/Message/Message'

const Home = lazy(() => import('./pages/Home/Home'));
const Error404 = lazy(() => import('./pages/Errror/404/Error404'));


const Layout = () => {

    /*
        Messaging settings
    */

    //message list
    const [messages, setMessages] = useState([])

    useEffect(() => {
        console.log(messages)
    }, [messages])

    /* Return the current time number. Used to create unique Id to each message based on the time they were send */
    const getCurrentTime = () => {
        const d = new Date()
        return d.getTime()
    }
    
    //Metthod called from other components to update the state
    const addMessage = (newMessage) => {
        console.log("Called")
        setMessages([...messages, {
            ...newMessage,
            creationTime: getCurrentTime()  
        }])
    }

    return(
        <>
            <Router>
                <Suspense fallback={<Spinner/>}>
                    <>
                        <MessageContext.Provider value={{ addMessage: addMessage }}>
                            <main> 
                                    <Routes>
                                        <Route path="/" element={<Home />}/>
                                        <Route path="*" element={<Error404/>} />
                                    </Routes>
                            </main>
                            <Footer />
                            {/* Section where the common messages and alerts to the user are made */}
                            <div style={{
                                position: "fixed",
                                top: "1rem",
                                right: "1rem",
                                zIndex: "100000000"
                            }}>

                                {/* Display the messages */}
                                { messages.map(message => (
                                    <Message 
                                        key={ "login-message-" + message.creationTime } 
                                        positiveReview={ message.positive } 
                                        clean={() => { setMessages(
                                            prevState => prevState.filter(i => i !== message)
                                            )}}
                                    >
                                        {message.text}
                                    </Message> 
                                )) 
                                }

                            </div>
                        </MessageContext.Provider>
                    </>
                </Suspense>
            </Router>
        </>
    );
}


export default Layout;

